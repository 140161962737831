import React from "react";
import "./landing.css";
import Navbar from "./navbar/navbar";
import Hero from "./Hero";
import FooterHero from "./footerCta";
import Footer from "./footer";
import AboutSection from "./AboutSection";
import FeatureGrid from "./featuresGrid";
import image1 from "./features/marketing/1.png";
import image2 from "./features/marketing/2.png";
import image3 from "./features/marketing/3.png";
import image4 from "./features/marketing/4.svg";
import image5 from "./features/marketing/5.png";
import useWindowSize from "../../utils/useWindowSize";

const features = [
  {
    id: 1,
    title: "Metasearch",
    description:
      "Expand your visibility across popular metasearch platforms like Google Hotel Ads and TripAdvisor. Capture the attention of travelers actively searching for accommodations to get more direct bookings",
    image: image1,
  },
  {
    id: 2,
    title: "Hotel Ads",
    description:
      "Showcase your property through targeted hotel ads on popular platforms. Reach your potential guests at the right moment with compelling offers and promotions.",
    image: image2,
  },
  {
    id: 3,
    title: "Search Ads",
    description:
      "Boost your visibility and secure top placement on search engines with targeted paid ads, ensuring your property stands out when travelers are looking for the perfect place to stay.",
    image: image3,
  },
  {
    id: 4,
    title: "SEO (Search Engine Optimization)",
    description:
      "Refine your website's content and structure to climb search engine rankings, driving organic traffic, boosting visibility, and capturing high-quality leads.",
    image: image4,
  },
  {
    id: 5,
    title: "SEM, Insta, FB, TikTok",
    description:
      "Harness the power of social media and search engine marketing (SEM) to connect with travelers across platforms like Instagram, Facebook, and TikTok. Expand your reach and boost brand awareness with innovative, targeted campaigns.",
    image: image5,
  },
];

const Marketing = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  return (
    <div className="">
      <Navbar />
      <Hero
        content={
          isMobile ? (
            <>
              {/* <h3 className="product_title_l">AI Marketing</h3> */}
              <h1 style={{ fontSize: "" }} className="text-dark onestfont">
                Because Your <br /> Perfect Guests <br /> Aren’t Going To <br />{" "}
                Find <span className="gradient_app onestfont">Themselves!</span>
              </h1>

              <p>
                Attract the right audience to your brand with tailored campaigns
                across social media, search engines, and metasearch
                platforms—optimized by advanced AI strategies.
              </p>
            </>
          ) : (
            <>
              {/* <h3 className="product_title_l">AI Marketing</h3> */}
              <h1 className="text-dark onestfont">
                Because your perfect guests
              </h1>
              <h1 className="text-dark onestfont">
                aren’t going to find
                <span className="gradient_app onestfont"> themselves!</span>
              </h1>
              <p>
                Attract the right audience to your brand with tailored campaigns
                across social media, search engines, and metasearch
                platforms—optimized by advanced AI strategies.
              </p>
            </>
          )
        }
      />

      <FeatureGrid features={features} />

      <FooterHero />
      <Footer />
    </div>
  );
};

export default Marketing;
