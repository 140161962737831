import React, { useState } from "react";
import "./styles/breadcrumbs.css";
import MenuIcon from "./hamburger";
import Button from "./Button";
import BookingModal from "./bookingModal";

const logo =
  "https://cdn.prod.website-files.com/665dbdf119ee43cd42be2657/665dfa4ae79bf116e4bb5ec0_Group%202745%402x-p-500.png";

export const CalendlyLink =
  "https://calendly.com/anirudh10/30min?month=2025-02";

const navLinks = [
  {
    title: "Booking Engine",
    link: "booking-engine",
  },
  {
    title: "AI Marketing",
    link: "marketing",
  },
  {
    title: "AI Sales Concierge",
    link: "ai-sales-concierge",
  },

  {
    title: "About us",
    link: "about-us",
  },

  // {
  //   title: "Blogs",
  //   link: "/blogs",
  // },

  {
    title: "Login",
    target: "_blank",
    link: "https://admin.hotelzify.com",
  },
];

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);

  return (
    <>
      <nav className="hzl-navbar">
        <div className="hzl-container">
          <div className="hzl-logo-wrapper">
            {/* <Layers className="hzl-logo-icon" /> */}
            <img
              onClick={() => {
                window.location.href = "/";
              }}
              style={{ height: "40px" }}
              src={logo}
              alt="Hotelzify"
            />
          </div>

          <button
            onClick={() => setIsOpen(!isOpen)}
            className="hzl-menu-btn"
            aria-label="Toggle Menu"
          >
            <MenuIcon />
          </button>
        </div>
      </nav>

      <div className={`hzl-dropdown ${isOpen ? "open" : ""}`}>
        <div className="hzl-menu-content">
          <nav className="hzl-menu-grid">
            {navLinks.map((item) => (
              <a
                target={item?.target}
                href={item.link}
                className="hzl-menu-item"
              >
                <span>{item.title}</span>
              </a>
            ))}
          </nav>
          <div
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
              marginTop: "12px",
            }}
            className="btn_sec"
          >
            <Button
              onClick={() => {
                // setModal(true);
                window.location.href = CalendlyLink;
              }}
            >
              Book a Demo
            </Button>
          </div>
        </div>
      </div>

      <div
        className={`hzl-overlay ${isOpen ? "visible" : "hidden"}`}
        onClick={() => setIsOpen(false)}
      />
      {modal && <BookingModal onClose={() => setModal(false)} />}
    </>
  );
};

export default HamburgerMenu;
