import React, { useState } from "react";
import "../landing.css";
import Button from "../Button";
import useWindowSize from "../../../utils/useWindowSize";
import Breadcrumbs, { CalendlyLink } from "../breadcrumbs";
import BookingModal from "../bookingModal";
const logo =
  "https://cdn.prod.website-files.com/665dbdf119ee43cd42be2657/665dfa4ae79bf116e4bb5ec0_Group%202745%402x-p-500.png";

const navLinks = [
  {
    title: "Booking Engine",
    link: "/booking-engine",
  },
  {
    title: "AI Marketing",
    link: "/marketing",
  },
  {
    title: "AI Sales Concierge",
    link: "/ai-sales-concierge",
  },

  {
    title: "About us",
    link: "/about-us",
  },
  // {
  //   title: "Blogs",
  //   link: "/blogs",
  // }
];

const Navbar = () => {
  const { width } = useWindowSize();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const path = window.location.pathname;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  if (width <= 768) {
    return <Breadcrumbs />;
  }

  console.log(path);

  return (
    <nav>
      <div className="navbar_out containerLanding">
        <div className="navbar">
          <img
            style={{ cursor: "pointer", height: "50px" }}
            onClick={() => {
              window.location.href = "/";
            }}
            src={logo}
            alt=""
          />
          <ul className="nav-links">
            {navLinks.map((item) => (
              <li>
                <a
                  className={path === item.link ? "active_link_l" : ""}
                  href={item.link}
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
          <div className="btn_sec">
            <a
              className="login_btn"
              onClick={() => {
                window.location.href = "https://admin.hotelzify.com";
              }}
              style={{ cursor: "pointer" }}
            >
              Login
            </a>
            <Button
              onClick={() => {
                window.location.href = CalendlyLink;
              }}
            >
              Book a Demo
            </Button>
          </div>
        </div>
      </div>
      {isModalOpen && <BookingModal onClose={closeModal} />}
    </nav>
  );
};

export default Navbar;
