import React from "react";
import "./landing.css";
import Navbar from "./navbar/navbar";
import Hero from "./Hero";
import FooterHero from "./footerCta";
import Footer from "./footer";
import AboutSection from "./AboutSection";
import FeatureGrid from "./featuresGrid";
import image1 from "./features/be/1.png";
import image2 from "./features/be/2.png";
import image3 from "./features/be/3.png";
import image4 from "./features/be/4.png";
import image5 from "./features/be/5.png";
import Integrations from "./integrations";
import useWindowSize from "../../utils/useWindowSize";

const features = [
  {
    id: 1,
    title: "Abandoned cart",
    description:
      "Recover lost sales with automated reminders for customers who add items to their cart but leave without completing their transaction through a multi-step re-engagement strategy.",
    image: image1,
  },
  {
    id: 2,
    title: "Promotion Engine",
    description:
      "Build your revenue with scalable, predictable triggers that enable you to create campaigns, generate offers, discounts, and more while tracking the performance of each promotional effort.",
    image: image2,
  },
  {
    id: 3,
    title: "Price Intelligence",
    description:
      "We ensure your offered services display offers the lowest price for online booking, minimizing OTA rates and influencing guest flow.",
    image: image3,
  },
  {
    id: 4,
    title: "One Page Checkout",
    description:
      "Simplify your checkout process with a frictionless single-page form that collects all necessary information efficiently. We ensure your visitors finish their transaction with minimal friction.",
    image: image4,
  },
  {
    id: 5,
    title: "Your hotel sells way more than just rooms",
    description:
      "When we say Hotelzify is way more than just a booking engine, we mean it. We enable you to sell everything effortlessly directly from your website.",
    image: image5,
  },
];

const BookingEngine = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  return (
    <div className="">
      <Navbar />
      <Hero
        content={
          isMobile ? (
            <>
              {/* <h3 className="product_title_l">Booking Engine</h3> */}
              <h1 style={{ lineHeight: "1.1" }} className="onestfont">
                <span className="text-dark onestfont">
                  From Visitor <br /> to Guest in
                </span>
              </h1>
              <h1>
                <span className="gradient_app onestfont">just 3 clicks</span>
              </h1>
              <p>
                Your guests are on your website… but your <br /> booking engine
                isn’t. <br /> Hotelzify turns your website  into a full ecommerce
                experience.
              </p>
            </>
          ) : (
            <>
              {/* <h3 className="product_title_l">Booking Engine</h3> */}
              <h1 className="onestfont">
                <span className="text-dark onestfont">
                  From Visitor to Guest in
                </span>
              </h1>
              <h1>
                <span className="gradient_app onestfont">just 3 clicks</span>
              </h1>
              <p>
                Your guests are on your website… but your booking engine isn’t. <br />
                Hotelzify  turns your website into a full ecommerce
                experience.
              </p>
            </>
          )
        }
      />

      <FeatureGrid features={features} />
      <Integrations />

      <FooterHero />
      <Footer />
    </div>
  );
};

export default BookingEngine;
